/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import SideAds from '@apps/components/SideAds';
import { BASE_URL } from '@apps/config';
import getJpjRunningNumber from '@apps/config/API/jpj-running-number.config';
import getNewsListing from '@apps/config/API/listing.config';
import { getNewCarById } from '@apps/config/API/new-car.config';
import getPostInit from '@apps/config/API/post-init.config';
import getSideNews from '@apps/config/API/side-news.config';
import {
  getSocialVideoChannels,
  getSocialVideos,
} from '@apps/config/API/social-video.config';
import TestScreen from '@apps/screens/Test';
import { localeConversions } from '@apps/utils/locale-conversion';
import { NextPage } from 'next';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import getSettings from '@apps/config/API/setting.config';

/**
 * ===========================
 * MAIN
 * ===========================
 */
const Page: NextPage = (props) => {
  const { lang } = useTranslation('common');
  const seoTitle =
    'Malaysia Automotive News: Latest, Car Launches, Reviews & Insights | Carz Automedia Malaysia';
  const currentUrl = lang === 'en' ? `${BASE_URL}` : `${BASE_URL}/${lang}`;

  return (
    <>
      <Head>
        <meta name="twitter:title" content={seoTitle} />
      </Head>
      <NextSeo title={seoTitle} canonical={currentUrl} />
      <SideAds
        adsKeyLeft={'news_listing_vertical_left'}
        adsKeyRight={'news_listing_vertical_right'}
      />
      <TestScreen {...props} />
    </>
  );
};

// ==============================
// SERVER SIDE
// ==============================

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps = async (ctx: any) => {
  const channelType = ctx?.query?.channel ?? '';
  const locale = ctx?.locale ?? 'en';
  const newLocale = localeConversions(locale);

  // Parallel API calls with error handling
  const [
    channelsData,
    videosData,
    data,
    newEvCar,
    jpjNumber,
    sideNews,
    postInit,
    settings,
  ]: any = await Promise.allSettled([
    getSocialVideoChannels(),
    getSocialVideos({ channelId: channelType }),
    getNewsListing({ latest: true, locale: newLocale || 'en', pageSize: 45 }),
    getNewCarById({ id: 4 }),
    getJpjRunningNumber({ featured: true }),
    getSideNews({ locale: newLocale }),
    getPostInit({ locale: newLocale }),
    getSettings(),
  ]);

  const topNumber = 6;
  const mainNews = data.value?.data?.[0];
  const topLatestNews = data?.value.data?.slice(1, topNumber);
  const remainLatestNews = data?.value.data?.slice(topNumber);
  const newCars = newEvCar?.value.data?.find((car: any) => {
    return car.new_car_feature_id === 4;
  });

  return {
    props: {
      data: {
        channels: channelsData?.value.data || [],
        videos: videosData?.value || [],
        latestNews: data?.value || [],
        newCars: newCars || [],
        jpjNumber: jpjNumber?.value || [],
        sideNews: sideNews?.value || [],
        postMostRead: postInit?.value?.post_most_read || [],
        postCategoryAll: postInit?.value?.post_all,
        mainNews: mainNews || null,
        topLatestNews: topLatestNews || null,
        remainLatestNews: remainLatestNews || null,
        settingBanner: settings?.value?.carz_setting_banner,
      },
    },
  };
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default Page;
